/* COLOR DEFINITIONS */
:root {
  --white: #ffffff;
  --ceramic500: #f5f5f5;
  --grey100: #d3d3d3;
  --grey300: #e9eef1;
  --grey500: #b9b6b6;
  --grey800: #808080;
  --black: #000000;

  --blue100: #b6d6f7;
  --blue300: #0066d0;
  --blue500: #003da6;
  --blue800: #002d72;

  --green300: #00b388;
  --green500: #99cc33;

  --red100: #ffd1d1;
  --red500: #d50538;

  --yellow300: #fff0c1;
  --yellow500: #ffcc00;
  --orange500: #ff985f;

  --shadow500: #e9eff6;
  --shadow800: #d3d3d3;
}

/* Custom CSS */
body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;*/
  font-family: "Avenir Next Regular", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  ::-webkit-scrollbar {
    position: relative;
    display: flex;
    left: 4rem;
    width: 0.6rem;
    height: 0.6rem;
    border-radius: 4rem;
  }
  ::-webkit-scrollbar-button {
    display: none;
  }
  ::-webkit-scrollbar-track {
    border-radius: 4rem;
    background-color: var(--blue500);
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4rem;
    background-color: var(--blue300);
  }
  ::-webkit-scrollbar-track-piece {
    border-radius: 4rem;
    background-color: var(--grey300);
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "FreshJuiceRegular";
  src: local("FreshJuiceRegular"),
    url("./resources/font/FreshJuiceRegular.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir Next Bold";
  src: local("Avenir Next Bold"),
    url("./resources/font/avenirNext/AvenirNext-Bold-01.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir Next Bold Italic";
  src: local("Avenir Next Bold Italic"),
    url("./resources/font/avenirNext/AvenirNext-BoldItalic-02.ttf")
      format("truetype");
}

@font-face {
  font-family: "Avenir Next DemiBold";
  src: local("Avenir Next DemiBold"),
    url("./resources/font/avenirNext/AvenirNext-DemiBold-03.ttf")
      format("truetype");
}

@font-face {
  font-family: "Avenir Next DemiBold Italic";
  src: local("Avenir Next DemiBold Italic"),
    url("./resources/font/avenirNext/AvenirNext-DemiBoldItalic-04.ttf")
      format("truetype");
}

@font-face {
  font-family: "Avenir Next Heavy";
  src: local("Avenir Next Heavy"),
    url("./resources/font/avenirNext/AvenirNext-Heavy-09.ttf")
      format("truetype");
}

@font-face {
  font-family: "Avenir Next HeavyItalic";
  src: local("Avenir Next HeavyItalic"),
    url("./resources/font/avenirNext/AvenirNext-HeavyItalic-10.ttf")
      format("truetype");
}

@font-face {
  font-family: "Avenir Next Italic";
  src: local("Avenir Next Italic"),
    url("./resources/font/avenirNext/AvenirNext-Italic-05.ttf")
      format("truetype");
}

@font-face {
  font-family: "Avenir Next Medium";
  src: local("Avenir Next Medium"),
    url("./resources/font/avenirNext/AvenirNext-Medium-06.ttf")
      format("truetype");
}

@font-face {
  font-family: "Avenir Next Medium Italic";
  src: local("Avenir Next Medium"),
    url("./resources/font/avenirNext/AvenirNext-MediumItalic-07.ttf")
      format("truetype");
}

@font-face {
  font-family: "Avenir Next Regular";
  src: local("Avenir Next Regular"),
    url("./resources/font/avenirNext/AvenirNext-Regular-08.ttf")
      format("truetype");
}

@font-face {
  font-family: "Avenir Next UltraLight";
  src: local("Avenir Next UltraLight"),
    url("./resources/font/avenirNext/AvenirNext-UltraLight-11.ttf")
      format("truetype");
}

@font-face {
  font-family: "Avenir Next UltraLight Italic";
  src: local("Avenir Next UltraLight Italic"),
    url("./resources/font/avenirNext/AvenirNext-UltraLightItalic-12.ttf")
      format("truetype");
}
