.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

#root {
  position: relative;
  min-height: 100vh;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "FreshJuiceRegular";
  src: local("FreshJuiceRegular"),
  url("./resources/font/FreshJuiceRegular.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir Next Bold";
  src: local("Avenir Next Bold"),
  url("./resources/font/avenirNext/AvenirNext-Bold-01.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir Next Bold Italic";
  src: local("Avenir Next Bold Italic"),
  url("./resources/font/avenirNext/AvenirNext-BoldItalic-02.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir Next DemiBold";
  src: local("Avenir Next DemiBold"),
  url("./resources/font/avenirNext/AvenirNext-DemiBold-03.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir Next DemiBold Italic";
  src: local("Avenir Next DemiBold Italic"),
  url("./resources/font/avenirNext/AvenirNext-DemiBoldItalic-04.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir Next Heavy";
  src: local("Avenir Next Heavy"),
  url("./resources/font/avenirNext/AvenirNext-Heavy-09.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir Next HeavyItalic";
  src: local("Avenir Next HeavyItalic"),
  url("./resources/font/avenirNext/AvenirNext-HeavyItalic-10.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir Next Italic";
  src: local("Avenir Next Italic"),
  url("./resources/font/avenirNext/AvenirNext-Italic-05.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir Next Medium";
  src: local("Avenir Next Medium"),
  url("./resources/font/avenirNext/AvenirNext-Medium-06.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir Next Medium Italic";
  src: local("Avenir Next Medium"),
  url("./resources/font/avenirNext/AvenirNext-MediumItalic-07.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir Next Regular";
  src: local("Avenir Next Regular"),
  url("./resources/font/avenirNext/AvenirNext-Regular-08.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir Next UltraLight";
  src: local("Avenir Next UltraLight"),
  url("./resources/font/avenirNext/AvenirNext-UltraLight-11.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir Next UltraLight Italic";
  src: local("Avenir Next UltraLight Italic"),
  url("./resources/font/avenirNext/AvenirNext-UltraLightItalic-12.ttf") format("truetype");
}

.lucide:hover {
    filter: brightness(110%);
}