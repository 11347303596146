.button {
  --border: var(--blue500);
  --highlight: var(--ceramic500);
  --shadow: var(--grey100);
  display: flex;
  height: 2.5rem;
  user-select: none;
  align-items: center;
  gap: 0.25rem;
  white-space: nowrap;
  border-radius: 0.5rem;
  border-style: none;
  background-color: white;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
  color: black;
  text-decoration-line: none;
  outline-width: 2px;
  outline-offset: 2px;
  outline-color: var(--blue800);
  box-shadow: inset 0 0 0 1px var(--border), inset 0 2px 0 var(--highlight),
    inset 0 -1px 0 var(--shadow), 0 1px 1px var(--shadow);
  min-width: 200px;
  justify-content: space-between;
}

.button[aria-disabled="true"] {
  opacity: 0.5;
}

.button[data-focus-visible] {
  outline-style: solid;
}

.button:active,
.button[data-active] {
  padding-top: 0.125rem;
  box-shadow: inset 0 0 0 1px var(--border), inset 0 2px 0 var(--border);
}

@media (min-width: 640px) {
  .button {
    gap: 0.5rem;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
}

.label {
  padding-left: 1rem;
}

.popover {
  z-index: 50;
  display: flex;
  max-height: min(var(--popover-available-height, 300px), 300px);
  flex-direction: column;
  overflow: auto;
  overscroll-behavior: contain;
  border-radius: 0.5rem;
  border-width: 1px;
  border-style: solid;
  border-color: hsl(204 20% 88%);
  background-color: white;
  padding: 0.5rem;
  color: black;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.popover:focus-visible,
.popover[data-focus-visible] {
  outline: 2px solid hsl(204 100% 40%);
  outline-offset: -1px;
}

.select-item {
  display: flex;
  cursor: default;
  scroll-margin: 0.5rem;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.25rem;
  padding: 0.5rem;
  outline: none !important;
}

.select-item[aria-disabled="true"] {
  opacity: 0.5;
}

.select-item[data-active-item] {
  background-color: hsl(204 100% 40%);
  color: white;
}
