/*
  Josh's Custom CSS Reset
  https://www.joshwcomeau.com/css/custom-css-reset/
*/

div[role="reset"] *,
div[role="reset"] *::before,
div[role="reset"] *::after {
  box-sizing: border-box;
}

div[role="reset"] * {
  margin: 0;
}

div[role="reset"] {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}
div[role="reset"] img,
div[role="reset"] picture,
div[role="reset"] video,
div[role="reset"] canvas,
div[role="reset"] svg {
  display: block;
  max-width: 100%;
}
div[role="reset"] input,
div[role="reset"] button,
div[role="reset"] textarea,
div[role="reset"] select {
  font: inherit;
}
div[role="reset"] p,
div[role="reset"] h1,
div[role="reset"] h2,
div[role="reset"] h3,
div[role="reset"] h4,
div[role="reset"] h5,
div[role="reset"] h6 {
  overflow-wrap: break-word;
}

div[role="reset"] {
  isolation: isolate;
}
