.tab-list {
  display: flex;
  gap: -0.2rem;
}

.tab {
  color: var(--blue300);
  display: flex;
  height: 2.5rem;
  user-select: none;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  white-space: nowrap;
  border-radius: 0.5rem 0.5rem 0 0;
  border-style: none;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
  text-decoration-line: none;
  outline-width: 2px;
  outline-offset: 2px;
  outline-color: var(--blue500);
}

.tab:hover {
}

.tab[aria-disabled="true"] {
  opacity: 0.5;
}

.tab[aria-selected="true"] {
  background-color: var(--white);
  color: var(--blue500);
}

.tab:hover[aria-selected="true"] {
}

.tab[data-focus-visible] {
  outline-style: solid;
}

.tab:active,
.tab[data-active] {
  padding-top: 0.125rem;
}

.tab:hover:where(.dark, .dark *) {
  background-color: rgb(255 255 255 / 0.1);
}

.tab[aria-selected="true"]:where(.dark, .dark *) {
  background-color: hsl(204 100% 40%);
  color: white;
}

.tab:hover[aria-selected="true"]:where(.dark, .dark *) {
  background-color: hsl(204 100% 32%);
}


