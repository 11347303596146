.dialog {
  position: fixed;
  inset: 0;
  z-index: 50;
  margin: auto;
  display: flex;
  height: fit-content;
  max-height: calc(100dvh - 2 * 0.75rem);
  flex-direction: column;
  gap: 1rem;
  overflow: auto;
  border-radius: 1rem;
  background-color: white;
  padding: 0;
  color: black;
  box-shadow: 0 25px 50px -12px var(--grey800);
}

@media (min-width: 720px) {
  .dialog {
    top: 18vh;
    margin-top: 0;
    max-height: 75vh;
    max-width: calc(60dvw - 2 * 0.75rem);
    border-radius: 1rem;
    padding: 0;
  }
}

.dialog:where(.dark, .dark *) {
  border-width: 1px;
  border-style: solid;
  border-color: hsl(204 4% 24%);
  background-color: hsl(204 4% 16%);
  color: white;
}

.heading {
  margin: 0;
  font-size: 2rem;
  font-weight: bold;
  background-color: var(--blue500);
  color: var(--white);
  text-align: center;
  padding-block: 0.5rem;
  position: relative;
}
